<template>
	<CalendarView />
</template>

<script>
import CalendarView from './calendar/Calendar'
export default {
	name: 'Calendar',
	components: { CalendarView },
}
</script>